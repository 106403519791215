import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"
import case0 from "../images/card/0.png"
import case1 from "../images/card/1.gif"
import case2 from "../images/card/2.png"
import case3 from "../images/card/3.gif"

const Card = () => {
  const [cardStatus, setCardStatus] = useState(2)
  // 0 front, 1 flipping to back, 2 back, 3 flipping to front

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay))
  }

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })

  const whichCard = () => {
    switch (cardStatus % 4) {
      case 0:
        return (
          <img
            className="w-full h-full object-contain"
            src={case0}
            id="case0"
            alt=""
            draggable="false"
          />
        )
      case 1:
        return (
          <img
            className="w-full h-full object-contain"
            src={case1}
            id="case1"
            alt=""
            draggable="false"
          />
        )
      case 2:
        return (
          <img
            className="w-full h-full object-contain"
            src={case2}
            id="case2"
            alt=""
            draggable="false"
          />
        )
      case 3:
        return (
          <img
            className="w-full h-full object-contain"
            src={case3}
            id="case3"
            alt=""
            draggable="false"
          />
        )
      default:
        return (
          <img
            className="w-full h-full"
            src={case0}
            id="case0"
            alt=""
            draggable="false"
          />
        )
    }
  }

  return (
    <button
      className={`block relative cursor-${
        cardStatus % 2 === 0 ? "pointer" : "default"
      }`}
      type="button"
      onClick={() => {
        if (cardStatus % 2 === 0) {
          setCardStatus(cardStatus + 1)
          timeout(1770).then(() => {
            setCardStatus(cardStatus + 2)
          })
        }
      }}
      style={{
        width: `${isMobile ? "95vw" : "calc(33vw + 116px)"}`,
        height: `${isMobile ? "95vw" : "calc(33vw + 116px)"}`,
        maxWidth: "549.1px",
        maxHeight: "550px",
      }}
    >
      {whichCard()}
      <div
        className={`dillon-hover z-50 absolute inset-0 ${
          cardStatus % 2 === 1 ? "hidden" : null
        }`}
        style={{
          margin: "27.6% 13.5% 27.5% 13.5%",
        }}
      />
    </button>
  )
}

export default Card
