import React from "react"
import { Link } from "gatsby"
import MediaQuery, { useMediaQuery } from "react-responsive"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/seo"
import Layout from "../components/layout"
import signGIF from "../images/gifs/sign.gif"
import Card from "../components/card"
import backToVideo from "../images/BacktoVideoStoreLeft.jpg"
import Loader from "../components/loader"

const ContactPage = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  return (
    <Layout old className="md:overflow-hidden" scroll={isMobile}>
      <SEO title="Contact" />
      <Loader duration={3000}>
        <div className="h-full sm:h-screen flex flex-col items-center w-full">
          <MediaQuery maxWidth={768}>
            <div
              className="flex items-center justify-center flex-col"
              style={{ width: "56.25vh", maxWidth: "95%" }}
            >
              <Link
                to="/"
                className="w-5/6 sm:w-3/4 z-10 mt-4 flex items-center justify-center"
                state={{ noLoad: true }}
              >
                <img
                  src={signGIF}
                  alt="Movie Theatre Sign Reading Dillon Dowdell"
                  className="w-full"
                  draggable="false"
                />
              </Link>
            </div>
            <div
              className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full md:wk-fill-available"
              style={{ maxWidth: "95%" }}
            >
              <div className="flex flex-col items-center justify-center w-full">
                <Card />
              </div>

              <div className="md:w-1/2 lg:w-5/12">
                <div className="h-full w-full flex flex-col items-center justify-end ">
                  <StaticImage
                    src="../images/mobilecontactcard.png"
                    className="w-3/5 -mb-2 flex-none"
                    alt=""
                    draggable="false"
                  />
                  <StaticImage
                    src="../images/stack.png"
                    alt=""
                    className="w-full max-w-lg "
                    imgClassName="w-full max-w-lg"
                    draggable="false"
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center py-4 z-30">
              <Link
                className="flex flex-row justify-start"
                style={{ width: "56.25vh", maxWidth: "95%" }}
                to="/"
                state={{ noLoad: true }}
              >
                <img
                  src={backToVideo}
                  className="h-12"
                  draggable="false"
                  alt=""
                />
              </Link>
            </div>
          </MediaQuery>
          <MediaQuery minWidth={769}>
            <div
              className="flex items-center justify-center flex-col"
              style={{ width: "56.25vh", maxWidth: "95%" }}
            >
              <Link
                to="/"
                className="w-5/6 sm:w-3/4 z-10 mt-4 flex items-center justify-center"
                state={{ noLoad: true }}
              >
                <img
                  src={signGIF}
                  alt="Movie Theatre Sign Reading Dillon Dowdell"
                  className="w-full"
                  draggable="false"
                />
              </Link>
            </div>
            <div
              className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full md:wk-fill-available"
              style={{ maxWidth: "95%" }}
            >
              <div className="flex flex-col items-center justify-start w-full md:w-1/2 lg:w-7/12 flex-1">
                <div
                  style={{ height: "calc(100% - 10rem)" }}
                  className="flex flex-col items-center justify-center w-full"
                >
                  <Card />
                </div>
              </div>
              <div className="md:w-1/2 lg:w-5/12">
                <div className="h-full w-full flex flex-col items-center justify-end ">
                  <StaticImage
                    src="../images/desktopcontactcard.png"
                    className="w-3/4 -mb-2 max-w-xs"
                    imgClassName="w-3/4 max-w-xs"
                    alt=""
                    draggable="false"
                  />

                  <StaticImage
                    src="../images/stack.png"
                    alt=""
                    className="w-full max-w-lg "
                    imgClassName="w-full max-w-lg"
                    draggable="false"
                  />
                </div>
              </div>
            </div>
            <div className="fixed bottom-0 left-0 w-full flex flex-row justify-start py-4 px-16 z-30">
              <Link className="" to="/" state={{ noLoad: true }}>
                <img
                  src={backToVideo}
                  className="h-20 dillon-hover"
                  draggable="false"
                  alt=""
                />
              </Link>
            </div>
          </MediaQuery>
        </div>
      </Loader>
    </Layout>
  )
}

export default ContactPage
